<template>
    <div style="padding: 200px;">
        <b-container>
            <b-card no-body style="border: 0px;background-color:#fff;">
                <h3 class="text-center my-3 pb-3">忘記密碼</h3>
                
                        <b-form-group class="course-search">
                            <b-input-group>
                                <template #prepend>
                                    <b-input-group-text>
                                        <b-icon-lock></b-icon-lock>
                                    </b-input-group-text>
                                </template>
                                <b-form-input
                                    id="loginpassword"
                                    type="password"
                                    :disabled="form1.data['pw'].only"
                                    :placeholder="form1.data['pw'].text"
                                    v-model="form1.data['pw'].val"
                                    @keypress="form1Keyup($event)"
                                ></b-form-input>
                            </b-input-group>
                        </b-form-group>

            

                

                 <b-form-group class="course-search">
                    <b-input-group>
                        <template #prepend>
                            <b-input-group-text>
                                <b-icon-lock></b-icon-lock>
                            </b-input-group-text>
                        </template>
                        <b-form-input
                            id="loginpassword"
                            type="password"
                            :disabled="form1.data['pw2'].only"
                            :placeholder="form1.data['pw2'].text"
                            v-model="form1.data['pw2'].val"
                            @keypress="form1Keyup($event)"
                        ></b-form-input>
                    </b-input-group>
                </b-form-group>
                <!--
                <b-row>
                    <b-col>
                        <b-form-group class="course-search">
                            <b-input-group>
                                <template #prepend>
                                    <b-input-group-text>
                                        <b-icon-info-circle></b-icon-info-circle>
                                    </b-input-group-text>
                                </template>
                                <b-form-input
                                    id="codelogin"
                                    type="text"
                                    :disabled="form1.data['code'].only"
                                    :placeholder="form1.data['code'].text"
                                ></b-form-input>
                            </b-input-group>
                        </b-form-group>
                    </b-col>
                    <b-col cols="auto">
                        <b-img :src="IsNull(form1.data['code'].src)" style="height:50px" fluid></b-img>
                    </b-col>
                </b-row>
                -->
                <b-button pill class="mb-3" variant="primary" block  @click="form1Save()">變更密碼</b-button>
         
        

            </b-card>

        </b-container>
    </div>
</template>
<script>


export default {
    name:'Forget',
    data(){
        return{
            data_in:{},
            form1:{
                title:'密碼變更',
                data:{
                    pw:{type:'text',val:'',text:'新密碼',only:false},
                    pw2:{type:'text',val:'',text:'確認新密碼',only:false},
                }
            }
        }
    },
    mounted(){
        let navBgSwitch=this._storeData('navBgSwitch')
        if(this.IsNull(navBgSwitch)!=''){
            navBgSwitch(false)
        }
        this.sessionF1()
    },
    methods:{
        sessionF1(url){
            if(this.IsNull(url)==''){
                let data1=this._go2({key:'Token'},'query')
                if(this.IsNull(data1)!=''){
                    this.data_in['Token']=data1
                    this.FunctionToken(this.FunctionCheckPassToken,{
                        PassToken:this.data_in['Token']
                    })
                }else{
                    this._go('/')
                }
            }
        },
        form1Keyup(event) {
            if (event.charCode === 13) {
                this.form1Save();
            }
        },
        form1Save(){
            let msg=''
            if(this.IsNull(this.form1.data['pw'].val)==''){
                msg+='未填寫新密碼\n'
            }
            if(this.IsNull(this.form1.data['pw2'].val)==''){
                msg+='未填寫確認新密碼\n'
            }
            if(msg==''){
                if(this.form1.data['pw'].val!=this.form1.data['pw2'].val){
                    msg+='新密碼與確認新密碼不符\n'
                }
            }
            
            if(msg!=''){
                alert(msg)
                return
            }
            this.FunctionToken(this.FunctionUpdateLastPass,{
                PassToken:this.data_in['Token'],
                DecryptionPass:this.form1.data['pw'].val
            })

        },



        //=================================================================

        FunctionCheckPassToken(data_in){
            this.apiCheckPassToken(data_in)
            .then(res=>{
                let json=JSON.parse(res.data)
                if(json.Status==false){
                    alert(json.Data)
                    this._go('/')
                }
            })
            .catch(err=>{
                this.Elog(err)
            })
        },
        FunctionUpdateLastPass(data_in){
            this.apiUpdateLastPass(data_in)
            .then(res=>{
                let json=JSON.parse(res.data)
                if(json.Status){
                    alert('密碼變更成功')
                    this._go('/')
                }else{
                    alert(json.Data)
                }
            })
            .catch(err=>{
                this.Elog(err)
            })
        }
     

    }
}
</script>